import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function customerratinglevelUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'one_customer_id', label: '客户ONEID', sortable: true },
    { key: 'company_name', label: '客户名称', sortable: true },
    //{ key: 'bg_code', label: '客户所属BG', sortable: true },
    { key: 'level_code', label: '客户等级', sortable: true },
    { key: 'level_name', label: '级别名称', sortable: true },
    { key: 'customer_type', label: '客户渠道', sortable: true },
    { key: 'customer_total_score', label: '评级总分数', sortable: true },
    { key: 'level_desc', label: '评级依据描述', sortable: true },
    { key: 'rating_time', label: '评级时间', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('customerratinglevel/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        one_customer_id: condition.value.one_customer_id,
        company_id: condition.value.company_id,
        level_code: condition.value.level_code,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        for(let i=0;i<list.length;i++){
          if(!isEmpty(list[i].ext)){
            list[i].company_name = list[i].ext.company_name
          }
        }
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
